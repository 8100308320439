
import { Component, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";
import TermsAndCondition from "@/components/TermsAndCondition.vue";

@Component({
  components: {
    TermsAndCondition
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
})
export default class ProfileView extends Vue {
  @Mutation
  setFillHeightOnMainContainer;

  async mounted() {
    this.setFillHeightOnMainContainer(false);
  }

  tabKeys = ["generalCondition"];
}

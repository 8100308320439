
import { Component, Vue } from "vue-property-decorator";
import { State as StateStore } from "../store";
import Card from "@/components/Card.vue";
import Btn from "@/components/Btn.vue";
import * as Api from "@/types/graphql";
import { Action, Mutation, State } from "vuex-class";
import gql from "graphql-tag";
import _ from "lodash";

@Component({
  components: { Card, Btn },
})
export default class TermsAndCondition extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action getUser;
  @Mutation showError;

  loading = false;
  termContent = "";
  accepted = false;

  async mounted() {
    await this.getUser();
    await this.getTerms();
  }

  async getTerms() {
    const termsRes = await this.$apollo.query({
      query: gql`
        query {
          TermCondition{
            guid
            accepted
            title
            content
          }
        }
      `,
      fetchPolicy: "no-cache",
    });
    if (_.isEmpty(termsRes.data.TermCondition)) {
      this.showError("Impossibile recuperare i Termini e Condizioni");
    } else {
      this.termContent = termsRes.data.TermCondition[0].content;
      this.accepted = termsRes.data.TermCondition[0].accepted == 1
    }
  }

  async save(){
    this.loading = true;
    await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $value: Int) {
          updateUser(guid: $guid, term_condition_accepted: $value)
        }
      `,
      variables: {
        guid: this.user.guid,
        value: 1,
      },
    });

    await this.getUser();
    this.loading = false;
  }
}
